import { Form, mailValidator, reg_mail } from '@code/ui';
import { FC } from 'react';
import { Input } from '@code/ui';
import { Captcha, CaptchaProps } from './Captcha';

interface EmailProps
  extends Pick<CaptchaProps, 'form' | 'countdown' | 'captcha'> {
  name: string;
  captchaName: string;
  allowEditEmail?: boolean;
}

export const Email: FC<EmailProps> = ({
  name,
  captchaName,
  allowEditEmail,
  ...props
}) => {
  return (
    <>
      <Form.Item
        name={name}
        rules={[{ required: true, message: '请输入邮箱' }, mailValidator]}
      >
        <Input
          placeholder="请输入邮箱"
          autoComplete="off"
          autoFocus={allowEditEmail}
          disabled={!allowEditEmail}
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: '请输入验证码' }]}
        name={captchaName}
      >
        <Captcha
          {...props}
          validate={{
            key: name,
            getValue() {
              return props.form.getFieldsValue(['login_id']).login_id;
            },
          }}
          onValidate={(value) => (!reg_mail.test(value) ? '邮箱格式错误' : '')}
        />
      </Form.Item>
    </>
  );
};
