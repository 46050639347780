import { useContext } from '@/store';
import { logError } from '@/utils';
import { useUnmount } from '@code/hooks';
import { Button, FormInstance, Input, InputProps, InputRef } from '@code/ui';
import { Track } from '@code/utils';
import { forwardRef, MouseEvent, useRef, useState } from 'react';
import { useCaptchaToken } from './useCaptchaToken';

export type CaptchaRef = InputRef;

export interface CaptchaProps
  extends Omit<InputProps, 'form' | 'suffix' | 'maxLength' | 'autoComplete'> {
  hasToken?: boolean;
  form: FormInstance;
  countdown: number;
  validate: { key: string; getValue(): string };
  captcha: (param?: any) => Promise<any>;
  onValidate: (value: string) => string;
  onSuccess?: () => void;
}

export const Captcha = forwardRef<CaptchaRef, CaptchaProps>(
  (
    {
      form,
      validate,
      countdown = 60,
      hasToken,
      captcha,
      onValidate,
      onSuccess,
      ...props
    },
    ref,
  ) => {
    const timer = useRef<number | null>(null);
    const { messageApi } = useContext();
    const [setHeaderSign] = useCaptchaToken(hasToken);
    const [captchaTime, setCaptchaTime] = useState(0);
    useUnmount(() => {
      if (timer.current != null) window.clearInterval(timer.current);
    });

    const handleCaptchaCode = async (e: MouseEvent<HTMLButtonElement, any>) => {
      e.stopPropagation();
      const { getValue, key } = validate;
      const value = getValue();
      // 判断如果为空触发此表单item校验
      if (!value) {
        form.validateFields([key]);
        return;
      }
      const errMsg = onValidate(value);
      if (errMsg) {
        return messageApi.error(errMsg);
      }
      const logins = {
        login_id: value?.trim(),
      };
      let num = countdown;
      setCaptchaTime(num);
      if (timer.current != null) window.clearInterval(timer.current);
      timer.current = window.setInterval(() => {
        num -= 1;
        setCaptchaTime(num);
        if (num == 0) {
          if (timer.current != null) window.clearInterval(timer.current);
        }
      }, 1000);
      try {
        await setHeaderSign(logins);
        await captcha(logins);
        messageApi.success('验证码已发送');
        Track.capture('【验证码】已发送', logins);
        onSuccess?.();
      } catch (error) {
        logError(error);
        Track.capture('【验证码】发送错误', logins);
      }
    };
    return (
      <Input
        placeholder="请输入验证码"
        {...props}
        maxLength={6}
        autoComplete="off"
        suffix={
          <Button
            disabled={captchaTime !== 0}
            type={captchaTime === 0 ? 'link' : 'text'}
            onClick={handleCaptchaCode}
          >
            {captchaTime === 0 ? '获取验证码' : `${captchaTime}s`}
          </Button>
        }
        ref={ref}
      />
    );
  },
);
