import { voiceCaptcha } from '@/api/user';
import { useContext } from '@/store';
import { useRequest } from '@code/hooks';
import { Button, Form, Input, Select } from '@code/ui';
import { useState } from 'react';
import { Captcha, CaptchaProps } from './Captcha';
import { useCaptchaToken } from './useCaptchaToken';

export interface PhoneCaptchaProps
  extends Pick<CaptchaProps, 'form' | 'countdown' | 'captcha'> {
  name: string;
  captchaName: string;
  captchaFor: 'validate' | 'user_register' | 'user_login' | 'change_phone';
  allowEditPhone?: boolean;
}

export const reg_tel =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

export function Phone({
  name,
  captchaName,
  allowEditPhone = true,
  captchaFor,
  ...props
}: PhoneCaptchaProps) {
  const { messageApi } = useContext();
  const [count, setCount] = useState(0);
  const [setHeaderSign] = useCaptchaToken(true);
  const login_id = Form.useWatch('login_id', props.form);
  const { run } = useRequest(
    async () => {
      const logins = {
        login_id: login_id?.trim(),
      };
      await setHeaderSign(logins);
      return voiceCaptcha({ ...logins, captcha_for: captchaFor });
    },
    {
      manual: true,
      onSuccess() {
        messageApi.success('语音验证码已发送，请注意来电');
      },
    },
  );
  return (
    <>
      <Form.Item
        name="login_id"
        rules={[
          { required: true, message: '请输入手机号' },
          () => ({
            validator(_, value) {
              if (!value || reg_tel.test(value) || !allowEditPhone) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('手机号格式错误'));
            },
          }),
        ]}
      >
        <Input
          type={allowEditPhone ? 'number' : 'text'}
          placeholder="请输入手机号"
          maxLength={11}
          autoComplete="off"
          autoFocus={true}
          disabled={!allowEditPhone}
          addonBefore={
            <Select
              className="!w-[70px]"
              defaultValue="86"
              disabled={!allowEditPhone}
              options={[{ label: '+86', value: '86' }]}
            />
          }
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: '请输入验证码' }]}
        name="captchaCode"
      >
        <Captcha
          {...props}
          hasToken
          onSuccess={() => setCount(count + 1)}
          validate={{
            key: name,
            getValue() {
              return props.form.getFieldsValue(['login_id']).login_id;
            },
          }}
          onValidate={(value) =>
            allowEditPhone && !reg_tel.test(value) ? '手机号格式错误' : ''
          }
        />
      </Form.Item>
      {count >= 2 && (
        <div className="mt-2 flex items-center justify-end text-xs text-foreground-2">
          收不到短信验证码？
          <Button type="link" size="small" onClick={run}>
            语音验证
          </Button>
        </div>
      )}
    </>
  );
}
