import { logError } from '@/utils';
import { loadAnimation } from '@/utils/lottie-web';
import { LogoOriginal } from '@code/icons';
import { Button, Link } from '@code/ui';
import { loginUrl } from '@code/utils';
import classNames from 'classnames';
import { PropsWithChildren, useEffect, useRef } from 'react';

export const Header = ({ children }: PropsWithChildren) => {
  return (
    <div className="w-full flex flex-col items-center mb-12">
      <Link href="/" aria-label="首页" className="mb-6 cursor-pointer">
        <LogoOriginal width={88} height={20} />
      </Link>
      <div className="mb-3 relative text-9 leading-[50px] font-normal text-foreground-1">
        {children}
      </div>
      <div className="flex items-center text-sm text-foreground-2">
        已有账户？
        <Button type="link" href={loginUrl({ a: '1' })}>
          去登录
        </Button>
      </div>
    </div>
  );
};

export const AuthContainer = ({
  animationDataUrl,
  children,
}: PropsWithChildren<{ animationDataUrl?: string }>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadAnimation({
      container: containerRef.current!,
      path: animationDataUrl,
      loop: false,
    })
      .then((animation) => {
        animation.play();
      })
      .catch((error) => {
        logError(error);
      });
  }, []);
  return (
    <div className="flex flex-col lg:fixed lg:top-0 w-full h-full overflow-auto">
      {!!animationDataUrl && (
        <div
          className="hidden lg:block absolute inset-0"
          ref={containerRef}
        ></div>
      )}
      <div
        className={classNames(
          'absolute top-0 flex w-full h-full lg:w-100',
          animationDataUrl
            ? 'lg:right-[10%]'
            : 'left-1/2 transform -translate-x-1/2',
        )}
      >
        <div className="m-auto w-full h-full lg:h-auto flex flex-col px-15 py-12 items-center bg-background-1 lg:rounded-6 overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};
