import * as React from 'react';

// 签名认证
export async function setHeaderSign(token: string, obj: any) {
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  let maxPos = chars.length;
  var code = '';
  for (let i = 0; i < 16; i++) {
    code += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  obj.nonce = code;
  const strToSign = Object.keys(obj)
    .sort()
    .map((key) => key + '=' + obj[key])
    .join('&');

  const { default: CryptoJS } = await import(
    /* webpackChunkName: "crypto-js" */
    'crypto-js'
  );

  // @ts-ignore
  React.Component.prototype.$axios.defaults.headers['X-1024Code-Sign'] =
    CryptoJS.HmacSHA256(strToSign, token).toString(CryptoJS.enc.Hex);
  // @ts-ignore
  React.Component.prototype.$axios.defaults.withCredentials = true;
}
