export var reg_mail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export var mailValidator = function mailValidator() {
  return {
    validator: function validator(_, value) {
      if (!value || reg_mail.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('邮箱格式错误'));
    }
  };
};

// https://regex101.com/r/VpFTNi/1
export var reg_username = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,16}$/;
export var usernameValidator = function usernameValidator() {
  return {
    validator: function validator(_, value) {
      if (!value || reg_username.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('用户名格式为 2-16 个字符/数字'));
    }
  };
};
export var reg_userslug = /^[a-zA-Z0-9]{5,16}$/;
export var userslugValidator = function userslugValidator() {
  return {
    validator: function validator(_, value) {
      if (!value || reg_userslug.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('仅支持英文和数字，长度限制为5-16位'));
    }
  };
};