import { captcha, captchaLogin, userLogin } from '@/api/user';
import { NotAccessRoutes } from '@/constant';
import { useContext } from '@/store';
import { Captcha } from '@/ui';
import { withServerPage } from '@/utils';
import { LogoOriginal } from '@code/icons';
import { Button, Form, Input, Link, reg_mail, Segmented } from '@code/ui';
import { loginUrl } from '@code/utils';
import { useRouter } from 'next/router';
import { Key, ReactNode, useMemo, useState } from 'react';
import { AuthContainer } from './signup/components';

interface Props {}

const { getServerSideProps, Page } = withServerPage<Props>(
  async () => ({ props: { routerName: '登录' } }),
  () => {
    const router = useRouter();
    const [form] = Form.useForm();
    const { event$ } = useContext();
    const [segmentedVal, setSegmentedVal] = useState<Key>('手机验证登录');
    const [loading, setLoading] = useState(false);

    const handleFinish = (values: any) => {
      switch (segmentedVal) {
        case '账号密码登录':
          handleLogin(values.phone, values.password);
          break;
        case '手机验证登录':
          handleLoginCode(values.login_id, values.captchaCode);
          break;
      }
    };

    const onLogin = () => {
      const oauth_callback = (router.query.oauth_callback ||
        encodeURIComponent(`${window.location.origin}/~`)) as string;
      setLoading(false);
      let href = decodeURIComponent(oauth_callback);
      href = href.replace(window.location.origin, '');
      if (NotAccessRoutes.includes(href)) {
        href = '/~';
      }
      router.push(href);
      event$.emit({ type: 'login' });
    };

    const handleLogin = (account: string, password: string) => {
      setLoading(true);
      const logins = {
        login_id: account?.trim(),
        password: password?.trim(),
      } as any;
      if (reg_mail.test(logins.login_id)) {
        logins.identity_by = 'email';
      }
      userLogin(logins)
        .then(onLogin)
        .catch(() => {
          setLoading(false);
        });
    };

    const handleLoginCode = (account: string, captchaCode: string) => {
      setLoading(true);
      const logins = {
        login_id: account?.trim(),
        captcha: captchaCode?.trim(),
      };
      captchaLogin(logins)
        .then(onLogin)
        .catch(() => {
          setLoading(false);
        });
    };

    const content = useMemo<ReactNode>(() => {
      switch (segmentedVal) {
        case '账号密码登录':
          return (
            <>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: '请输入手机号码或邮箱地址' },
                ]}
              >
                <Input
                  type="text"
                  placeholder="请输入手机号码或邮箱地址"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                name="password"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input
                  placeholder="请输入密码"
                  maxLength={20}
                  type="password"
                />
              </Form.Item>
              <div className="flex justify-end mt-2">
                <Button type="text" size="small" href={loginUrl({}, 'forget')}>
                  忘记密码
                </Button>
              </div>
            </>
          );
        case '手机验证登录':
          return (
            <Captcha.Phone
              key="password-phone"
              name="login_id"
              captchaName="captchaCode"
              captchaFor="user_login"
              captcha={captcha}
              form={form}
              countdown={60}
            />
          );

        default:
          return null;
      }
    }, [segmentedVal]);

    return (
      <AuthContainer
        animationDataUrl={
          router.query.a === '1'
            ? '/animations/dark/signup_to_signin.json'
            : '/animations/dark/start_signin.json'
        }
      >
        <div className="w-full flex flex-col items-center mb-12">
          <Link href="/" aria-label="首页" className="mb-6 cursor-pointer">
            <LogoOriginal width={88} height={20} />
          </Link>
          <div className="mb-3 text-12 leading-[70px] font-normal text-foreground-1">
            登录
          </div>
          <div className="flex items-center text-sm text-foreground-2">
            没有账户？
            <Button type="link" href={loginUrl({}, 'signup')}>
              去注册
            </Button>
          </div>
        </div>
        <div className="w-full flex flex-col">
          <Segmented
            block
            options={['手机验证登录', '账号密码登录']}
            value={segmentedVal}
            onChange={setSegmentedVal}
          />
          <div className="w-full mt-6">
            <Form
              name="captcha-login-form"
              form={form}
              onFinish={handleFinish}
              autoComplete="off"
              onEnter={() => {
                if (loading) return;
                form.submit();
              }}
            >
              {content}
            </Form>
          </div>
          <div className="w-full flex py-6 mt-6">
            <Button
              block
              size="large"
              loading={loading}
              type="primary"
              onClick={form.submit}
            >
              登 录
            </Button>
          </div>
        </div>
      </AuthContainer>
    );
  },
);

export { getServerSideProps };
export default Page;
