import { anonymousToken } from '@/api/user';
import { setHeaderSign } from '@/utils/common';
import useSWR from 'swr';

export const useCaptchaToken = (hasToken?: boolean) => {
  const { data } = useSWR('/assets/anonymous_token', async () =>
    hasToken ? anonymousToken() : undefined,
  );

  const token = data?.token;

  const setSign = async (logins: any) => {
    if (hasToken && token) await setHeaderSign(token, logins);
  };

  return [setSign] as const;
};
